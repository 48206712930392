import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Item,
  Label,
  Loader,
  Message,
  Segment,
  Select,
  Icon,
  Input
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { authAxios } from "../utils";
import {
  checkoutURL,
  orderSummaryURL,
  addCouponURL,
  addressListURL
} from "../constants";

require('dotenv').config()
const { REACT_APP_BACKEND_URL } = process.env;

const OrderPreview = props => {
  const { data } = props;
  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          <Item.Group relaxed>
            {data.order_items.map((orderItem, i) => {
              return (
                <Item key={i}>
                  <Item.Image
                    size="small"
                    src={`${REACT_APP_BACKEND_URL}${orderItem.item.image}`}
                  />
                  <Item.Content verticalAlign="middle">
                    <Item.Header as="a">
                      {orderItem.quantity} x {orderItem.item.title}
                    </Item.Header>
                    <Item.Extra>
                      <Label
                        circular
                        color='yellow'
                        key='yellow'
                        size='large'>
                              <NumberFormat value={orderItem.final_price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'VND '}
                                            suffix={',000'}/>
                      </Label>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              );
            })}
          </Item.Group>

          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>
                  Tổng: {
                        <NumberFormat value={data.total}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'VND '}
                                      suffix={',000'}/>}
                  {data.coupon && (
                    <Label color="green" style={{ marginLeft: "10px" }}>
                      Coupon Đang Sử Dụng: "{data.coupon.code}" tương đương {<NumberFormat value={data.coupon.amount}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  prefix={'VND '}
                                                  suffix={',000'}/>}


                    </Label>
                  )}
                </Item.Header>
              </Item.Content>
            </Item>
          </Item.Group>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

class CouponForm extends Component {
  state = {
    code: ""
  };

  handleChange = e => {
    this.setState({
      code: e.target.value
    });
  };

  handleSubmit = e => {
    const { code } = this.state;
    this.props.handleAddCoupon(e, code);
    this.setState({ code: "" });
  };

  render() {
    const { code } = this.state;
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label>Coupon code</label>
            <Input
              icon='tags'
              iconPosition='left'
              size='mini'
              label={{ tag: true, content: 'Nhập Coupon' }}
              labelPosition='right'
              placeholder="Nhập coupon.."
              value={code}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button as='div' labelPosition='right' type="submit">
            <Button color='red'>
              <Icon name='heart' />
              Redemn
            </Button>
          </Button>
        </Form>
      </React.Fragment>
    );
  }
}

class CheckoutForm extends Component {
  state = {
    data: null,
    loading: false,
    error: null,
    success: false,
    shippingAddresses: [],
    selectedShippingAddress: "",
    paymentMethods: [
      { value: 'cod', text: 'Thanh Toán Khi Nhận Hàng (COD)' },
      //{ value: 'momo', text: 'Vi MoMo' },
    ],
    selectedPaymentMethod: "",
  };

  componentDidMount() {
    this.handleFetchOrder();
    this.handleFetchShippingAddresses();
  }

  handleGetDefaultAddress = addresses => {
    const filteredAddresses = addresses.filter(el => el.default === true);
    if (filteredAddresses.length > 0) {
      return filteredAddresses[0].id;
    }
    return "";
  };


  handleFetchShippingAddresses = () => {
    this.setState({ loading: true });
    authAxios
      .get(addressListURL("S"))
      .then(res => {
        this.setState({
          shippingAddresses: res.data.map(a => {
            return {
              key: a.id,
              text: `${a.street_address}, ${a.apartment_address}, ${a.country}| SĐT:${a.phone_number}`,
              value: a.id
            };
          }),
          selectedShippingAddress: this.handleGetDefaultAddress(res.data),
          loading: false
        });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleFetchOrder = () => {
    this.setState({ loading: true });
    authAxios
      .get(orderSummaryURL)
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.props.history.push("/products");
        } else {
          this.setState({ error: err, loading: false });
        }
      });
  };

  handleAddCoupon = (e, code) => {
    e.preventDefault();
    this.setState({ loading: true });
    authAxios
      .post(addCouponURL, { code })
      .then(res => {
        this.setState({ loading: false });
        this.handleFetchOrder();
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleSelectChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handlePaymentMethod = (e, { name, value }) => {
    this.setState({ selectedPaymentMethod: value });
  };

  submit = ev => {
    ev.preventDefault();
    this.setState({ loading: true });
    const {
      selectedShippingAddress,
      selectedPaymentMethod,
    } = this.state;

    // COD payment
    if(selectedPaymentMethod === 'cod'){
      authAxios
        .post(checkoutURL, {
          payment_method: selectedPaymentMethod === 'momo' ? 'M' : 'C',
          selectedShippingAddress
        })
        .then(res => {
          this.setState({ loading: false, success: true });
        })
        .catch(err => {
          this.setState({ loading: false, error: err });
        });
    }

    //MOMO payment
    else if(selectedPaymentMethod === 'momo'){
      authAxios
        .post(checkoutURL, {
          payment_method: selectedPaymentMethod === 'momo' ? 'M' : 'C',
          selectedShippingAddress
        })
        .then(res => {
          this.setState({ loading: false, success: true });
        })
        .catch(err => {
          this.setState({ loading: false, error: err });
        });
    }



  };

  handleBackToOrderSummary = () => {
      this.props.history.push('/order-summary');
  };
  render() {
    const {
      data,
      error,
      loading,
      success,
      shippingAddresses,
      selectedShippingAddress,
      selectedPaymentMethod,
      paymentMethods,
    } = this.state;

    if(success){
      // if checkout success, display congratulations.
      return (
        <div>
          {success && (
            <Message positive center>
              <Header>
                Đặt hàng thành công!
              </Header>
              <p>Xem tình trạng đơn hàng: <b><Link to="/profile">Profile</Link></b></p>
              <p>Phước Sơn sẽ liên hệ với quý khách để xác nhận đơn hàng, Phước Sơn chân thành cảm ơn quý khách.</p>
            </Message>
          )}
        </div>);
    }else{
      // render checkout page
      return (
        <div>
          {error && (
            <Message
              error
              header="There was some errors with your submission"
              content={JSON.stringify(error)}
            />
          )}

          {loading && (
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </Segment>
          )}

          <Button labelPosition='left'
                  icon='left chevron'
                  content='Quay Lại Giỏ Hàng'
                  onClick={() => this.handleBackToOrderSummary()}
                  color='brown'
          />

          <OrderPreview data={data} />
          <Divider />
          <CouponForm
            handleAddCoupon={(e, code) => this.handleAddCoupon(e, code)}
          />
          <Divider />

          <Header>Chọn một địa chỉ nhận hàng</Header>
          {shippingAddresses.length > 0 ? (
            <Select
              name="selectedShippingAddress"
              value={selectedShippingAddress}
              clearable
              options={shippingAddresses}
              selection
              onChange={this.handleSelectChange}
            />
          ) : (
            <p>
              Bạn cần có <Link to="/profile">thêm địa chỉ nhận hàng</Link>
            </p>
          )}
          <Divider />

          { shippingAddresses.length < 1 ? (
            <p>Bạn cần điền vào địa chỉ để tiếp tục thanh toán</p>
          ) : (
            <React.Fragment>
              <Header>Hãy tiếp tục tiến hành thanh toán:</Header>
              <Container style={{margin: "10px"}}>
                <Select
                    placeholder = 'Chọn một hình thức thanh toán'
                    name="selectedPaymentMethod"
                    value={selectedPaymentMethod}
                    clearable
                    options={paymentMethods}
                    selection
                    onChange={this.handlePaymentMethod}
                />
              </Container>

              {(selectedPaymentMethod === 'cod') ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    primary
                    onClick={this.submit}
                    style={{ marginTop: "10px" }}
                    basic
                    color='blue'
                    content='Blue'
                  >
                    <Icon name='handshake outline'/>COD - Thanh Toán Khi Nhận Hàng
                  </Button>
              ): (selectedPaymentMethod === 'momo') ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    onClick={this.submit}
                    style={{ marginTop: "10px" }}
                    basic
                    color='pink'
                    content='Pink'
                  >
                    <Icon name='qrcode' />MOMO - Thanh Toán Qua Ví Momo
                  </Button>
              ): (
                <p>Chưa chọn hình thức thanh toán</p>
              )}
              {success && (
                <Message positive center>
                  <Header>
                    Đặt hàng thành công!
                  </Header>
                  <p>Xem tình trạng đơn hàng tại đây: <b><Link to="/profile">Profile</Link></b></p>
                  <p>Phước Sơn sẽ liên hệ với quý khách để xác nhận đơn hàng, Phước Sơn chân thành cảm ơn quý khách.</p>
                </Message>
              )}
            </React.Fragment>
          )}
        </div>);
    }


  }
}
export default CheckoutForm;
