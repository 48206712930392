import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
  Transition
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import { fetchCart } from "../store/actions/cart";

import logo from '../custom_media/logo_PhuocSon.png';
import home_main from '../custom_media/home_main.JPG';

// import nestle_logo from '../custom_media/others/Nescafe-Logo.png';s
// import sucafina_logo from '../custom_media/others/sucafina-logo.png';
// import neumann_logo from '../custom_media/others/Neuman_logo.png';
// import volcafe_logo from '../custom_media/others/volcafe_logo.png';
// import louis_coffee_logo from '../custom_media/others/louis_logo.jpg';

import program_4c_logo from '../custom_media/others/program_4c.png';


class CustomLayout extends React.Component {
  state = {
    width: window.innerWidth,
    visible: true,
  };

  componentDidMount() {
    this.props.fetchCart();
  }

  render() {
    const { authenticated, cart, loading, width, visible } = this.props;
    const isMobile = width < 500;
    return (
        <div>
          {/* NAV */}
          {this.props.location.pathname === "/" ? (
              <Segment
                  inverted
                  textAlign='center'
                  style={{  maxHeight: 675,
                            padding: '1em 0em',
                            backgroundImage: `url(${home_main})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                  vertical
              >
                <Menu fixed='top' inverted size='small'>
                  <Container>
                    <Link to="/">
                      <Image  style={{width:'50px', height:'auto'}}
                              src={logo}/>
                    </Link>
                    <Dropdown text='Phước Sơn' className='link item'>
                      <Dropdown.Menu style={{ backgroundColor: "black" }}>
                        <Dropdown.Item>
                                      <Icon name='info circle' style={{ color: "white" }}/>
                                      <Link to="/about" >Giới thiệu</Link>
                        </Dropdown.Item>
                        {/* removed (can be added later)
                        <Dropdown.Item>
                                      <Icon name='tv' style={{ color: "white" }}/>
                                      <Link to="/articles" >Bảng Tin</Link>
                        </Dropdown.Item>
                        */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link to="/products">
                      <Menu.Item header>Cửa Hàng</Menu.Item>
                    </Link>
                    <Link to="/hotel">
                      <Menu.Item header>Khách Sạn</Menu.Item>
                    </Link>
                    {/*
                    {authenticated ? (
                      <React.Fragment>
                        <Menu.Menu position="right">
                          <Dropdown
                            icon="cart"
                            loading={loading}
                            text={`${cart !== null ? cart.order_items.length : 0}`}
                            pointing="top right"
                            className="link item"
                          >
                            <Dropdown.Menu>
                              {cart !== null ? (
                                <React.Fragment>
                                  {cart.order_items.map(order_item => {
                                    return (
                                      <Dropdown.Item key={order_item.id}>
                                        {order_item.quantity} x {order_item.item.title}
                                      </Dropdown.Item>
                                    );
                                  })}
                                  {cart.order_items.length < 1 ? (
                                    <Dropdown.Item>Chưa có sản phầm nào trong giỏ hàng</Dropdown.Item>
                                  ) : null}
                                  <Dropdown.Divider />

                                  <Dropdown.Item
                                    icon="arrow right"
                                    text="Checkout"
                                    onClick={() =>
                                      this.props.history.push("/order-summary")
                                    }
                                  />
                                </React.Fragment>
                              ) : (
                                <Dropdown.Item>Chưa có sản phầm nào trong giỏ hàng</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>

                          <Dropdown icon="user"  text=""  pointing="top right"  className="link item">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="user"
                                text="Profile"
                                onClick={() =>
                                  this.props.history.push("/profile")
                                }
                              />

                              <Dropdown.Item
                                icon="sign out"
                                text="Đăng Xuất"
                                onClick={() => this.props.logout()}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Menu.Menu>
                      </React.Fragment>
                    ) : (
                      <Menu.Menu position="right">
                        <Link to="/login">
                          <Menu.Item header>Đăng Nhập</Menu.Item>
                        </Link>
                      </Menu.Menu>
                    )}
                    */}
                  </Container>
                </Menu>

                {/* HomepageLayout (big image at home page) */}
                <Container>
                  <div>
                    <Header
                      as='h3'
                      content='Phước Sơn'
                      inverted
                      style={{
                        fontSize: isMobile ? '1.5em' : '5em',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: isMobile ? '0.5em' : '3em',
                      }}
                    />
                    <Header
                      as='h4'
                      content='Vì Một Việt Nam Tươi Đẹp Hơn'
                      inverted
                      style={{
                        fontSize: isMobile ? '1.0em' : '1.7em',
                        fontWeight: 'normal',
                        marginTop: isMobile ? '0.5em' : '1.5em',
                      }}
                    />

                    <React.Fragment>
                      <div>
                        <br/><br/>
                        <br/><br/>
                        <br/><br/>
                        <br/>
                        <span>Cuộn<br/>Xuống</span>
                      </div>
                      <Transition animation='bounce' duration={1500} visible={visible}>
                          <Icon className='angle double down'/>
                      </Transition>
                    </React.Fragment>
                  </div>
                </Container>
              </Segment>
            ) :(
              <Menu fixed='top' inverted size='small'>
                <Container>
                  <Link to="/">
                    <Image  style={{width:'50px', height:'auto'}}
                            src={logo} alt="logo"/>
                  </Link>
                  <Dropdown text='Phước Sơn' className='link item'>
                    <Dropdown.Menu style={{ backgroundColor: "black" }}>
                      <Dropdown.Item>
                                    <Icon name='info circle' style={{ color: "white" }}/>
                                    <Link to="/about" >Giới thiệu</Link>
                      </Dropdown.Item>
                      {/* removed (can be added later)
                      <Dropdown.Item>
                                    <Icon name='tv' style={{ color: "white" }}/>
                                    <Link to="/articles" >Bảng Tin</Link>
                      </Dropdown.Item>
                      */}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Link to="/products">
                    <Menu.Item header>Cửa Hàng</Menu.Item>
                  </Link>
                  <Link to="/hotel">
                    <Menu.Item header>Khách Sạn</Menu.Item>
                  </Link>
                  {/*
                  {authenticated ? (
                    <React.Fragment>
                      <Menu.Menu position="right">
                        <Dropdown
                          icon="cart"
                          loading={loading}
                          text={`${cart !== null ? cart.order_items.length : 0}`}
                          pointing="top right"
                          className="link item"
                        >
                          <Dropdown.Menu>
                            {cart !== null ? (
                              <React.Fragment>
                                {cart.order_items.map(order_item => {
                                  return (
                                    <Dropdown.Item key={order_item.id}>
                                      {order_item.quantity} x {order_item.item.title}
                                    </Dropdown.Item>
                                  );
                                })}
                                {cart.order_items.length < 1 ? (
                                  <Dropdown.Item>Chưa có sản phẩm nào trong giỏ hàng</Dropdown.Item>
                                ) : null}
                                <Dropdown.Divider />

                                <Dropdown.Item
                                  icon="arrow right"
                                  text="Checkout"
                                  onClick={() =>
                                    this.props.history.push("/order-summary")
                                  }
                                />
                              </React.Fragment>
                            ) : (
                              <Dropdown.Item>Chưa có sản phẩm nào trong giỏ hàng</Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown icon="user"  text=""  pointing="top right"  className="link item">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon="user"
                              text="Profile"
                              onClick={() =>
                                this.props.history.push("/profile")
                              }
                            />

                            <Dropdown.Item
                              icon="sign out"
                              text="Đăng Xuất"
                              onClick={() => this.props.logout()}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Menu.Menu>
                    </React.Fragment>
                  ) : (
                    <Menu.Menu position="right">
                      <Link to="/login">
                        <Menu.Item header>Đăng Nhập</Menu.Item>
                      </Link>
                    </Menu.Menu>
                  )}
                  */}
                </Container>
              </Menu>
            )
          }

          {/* MAIN */}
          <Container style={{ margin: "2em 0em 0em", padding: "2em 0em" }}>
            {this.props.children}
          </Container>


          {/* FOOTER */}
          <Segment
            inverted
            vertical
            style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
          >
            <Container textAlign="center">
              <Grid divided inverted stackable>
                <Grid.Column width={8}>
                  <Header inverted as="h4" content="Liên hệ PHƯỚC SƠN™" />
                  <Container textAlign='justified'>
                    <Divider />
                     <Container style={{ padding: "2em 0em" }}>
                        <Icon className="home" color='yellow'/> Công ty Phước Sơn
                           <p style={{paddingLeft: "1em"}}>
                                <a href="https://goo.gl/maps/6xA9q2Yx34T4q7jp9" target="_blank" rel="noopener noreferrer">
                                    <Icon className="map marker"/> </a>Công ty Phước Sơn, Gia Lâm, Lâm Hà, Lâm Đồng
                                <br />
                                <Icon className="clock outline"/>Giờ làm việc: <strong>Thứ 2 -&gt; thứ 7 </strong>từ 8:00
                                   đến 21:00 | <strong>Chủ Nhật</strong>  từ&nbsp;8:30đến 17:30
                                <br />
                                <Icon className="phone"/>0263.3852.364

                           </p>

                        <Icon className="home" color='yellow'/>Khách sạn Phước Sơn
                           <p style={{paddingLeft: "1em"}}>
                                <a href="https://goo.gl/maps/25p4sMHvK6av4K4JA" target="_blank" rel="noopener noreferrer">
                                    <Icon className="map marker"/> </a>Số 6G, Nam Kỳ Khởi Nghĩa, Phường 1, Đà Lạt, Lâm Đồng
                                <br />
                                <Icon className="clock outline"/>Giờ làm việc: <strong>Thứ 2 -&gt; Chủ Nhật</strong> từ 6:00
                                   đến 22:00
                                <br />
                                <Icon className="phone"/>0964.500.289

                           </p>
                     </Container>
                  </Container>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header inverted as="h4" content="Cà phê của chúng tôi" />
                  <Divider/>
                  <List link inverted>
                    {/*
                    <List.Item as="a" href="https://www.nestle.com.vn/vi" target="_blank">
                            <Image centered size="tiny" src={nestle_logo}/>
                    </List.Item>
                    <List.Item as="a" href="https://sucafina.com/apac/origins/vietnam" target="_blank">
                            <Image centered size="tiny" src={sucafina_logo}/>
                    </List.Item>
                    <List.Item as="a" href="https://www.nkg.net/" target="_blank">
                            <Image centered size="tiny" src={neumann_logo}/>
                    </List.Item>
                    <List.Item as="a" href="https://volcafespecialty.com/vietnam/" target="_blank">
                            <Image centered size="tiny" src={volcafe_logo}/>
                    </List.Item>
                    <List.Item as="a" href="https://www.ldc.com/vn/vi/" target="_blank">
                            <Image centered size="tiny" src={louis_coffee_logo}/>
                    </List.Item>
                    */}
                    <List.Item as="a" href="https://www.4c-services.org/"target="_blank">
                            <Image centered size="tiny" src={program_4c_logo}/>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={4}>
                  {isMobile ? (<Divider/>) : (<br/>)}
                  <List link inverted>
                    <List.Item as="a" href="/">Trang chủ</List.Item>
                    <List.Item as="a" href="/about">Giới thiệu Công ty</List.Item>
                    {/*
                    <List.Item as="a" href="/articles">Bảng Tin</List.Item>
                    */}
                    <List.Item as="a" href="/sustainability">Phát triển bền vững</List.Item>
                    <List.Item as="a" href="#">Đội ngũ nhân sự</List.Item>
                    <List.Item as="a" href="/career">Tuyển dụng</List.Item>
                  </List>
                </Grid.Column>
              </Grid>

              <Divider inverted section />
              <Image centered size="tiny" src={logo} />
              <List horizontal inverted divided link size="small">
                <List.Item as="a" href="#">
                  Liên Hệ
                </List.Item>
                <List.Item as="a" href="#">
                  Copyright 2020 © <b>Phước Sơn™</b>
                </List.Item>
                <List.Item as="a" href="#">
                  Privacy Policy
                </List.Item>
              </List>
            </Container>
          </Segment>
        </div>);
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    cart: state.cart.shoppingCart,
    loading: state.cart.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    fetchCart: () => dispatch(fetchCart())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
