import React from "react";
import NumberFormat from 'react-number-format';
import {
  Container,
  Dimmer,
  Header,
  Icon,
  Label,
  Loader,
  Table,
  Button,
  Message,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { authAxios } from "../utils";
import { fetchCart } from "../store/actions/cart";
import {
  addToCartURL,
  orderSummaryURL,
  orderItemDeleteURL,
  orderItemUpdateQuantityURL
} from "../constants";

class OrderSummary extends React.Component {
  state = {
    data: null,
    error: null,
    loading: false
  };

  componentDidMount() {
    this.handleFetchOrder();
    this.props.fetchCart();
  }

  handleFetchOrder = () => {
    this.setState({ loading: true });
    authAxios
      .get(orderSummaryURL)
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.setState({
            error: "Không có sản phẩm nào trong giỏ",
            loading: false
          });
        } else {
          this.setState({ error: err, loading: false });
        }
      });
  };

  handleAddToCart = slug => {
    this.setState({ loading: true });
    authAxios
      .post(addToCartURL, { slug })
      .then(res => {
        this.handleFetchOrder();
        this.props.fetchCart();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleRemoveQuantityFromCart = slug => {
    this.setState({ loading: true });
    authAxios
      .post(orderItemUpdateQuantityURL, { slug })
      .then(res => {
        this.handleFetchOrder();
        this.props.fetchCart();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  handleRemoveItem = itemID => {
    this.setState({ loading: true });
    authAxios
      .delete(orderItemDeleteURL(itemID))
      .then(res => {
        this.handleFetchOrder();
        this.props.fetchCart();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  handleKeepShopping = () => {
      this.props.history.push('/products');
  };

  render() {
    const { data, error, loading } = this.state;
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    return (
      <Container>
        <Button labelPosition='left'
                icon='left chevron'
                content='Tiếp Tục Xem Sản Phẩm'
                onClick={() => this.handleKeepShopping()}
                color='brown'
        />
        <Header>Tóm tắt đơn hàng</Header>
        {error && (
          <Message
            error
            header="There was an error"
            content={JSON.stringify(error)}
          />
        )}
        {loading && (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {data && (
          <Table unstackable selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell>STT #</Table.HeaderCell>
                <Table.HeaderCell>Sản Phẩm</Table.HeaderCell>
                <Table.HeaderCell>Giá</Table.HeaderCell>
                <Table.HeaderCell>Số Lượng</Table.HeaderCell>
                <Table.HeaderCell>Tạm Tính</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.order_items.map((orderItem, i) => {
                return (
                  <Table.Row key={orderItem.id} textAlign='center'>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>
                      {orderItem.item.title}
                    </Table.Cell>
                    <Table.Cell>
                      <NumberFormat value={orderItem.item.price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'VND '}
                                    suffix={',000'}/>

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon
                        name="minus"
                        style={{ float: "left", cursor: "pointer" }}
                        onClick={() =>
                          this.handleRemoveQuantityFromCart(orderItem.item.slug)
                        }
                      />
                      {orderItem.quantity}
                      <Icon
                        name="plus"
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => this.handleAddToCart(orderItem.item.slug)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {orderItem.item.discount_price && (
                        <Label color="green" ribbon>
                          Tiết Kiệm <NumberFormat value={(orderItem.item.price - orderItem.item.discount_price)*orderItem.quantity}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'VND '}
                                                        suffix={',000'}/>
                        </Label>
                      )}
                      {
                        <NumberFormat value={orderItem.final_price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'VND '}
                                      suffix={',000'}/>
                      }
                      <Icon
                        name="trash"
                        color="red"
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => this.handleRemoveItem(orderItem.id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              <Table.Row textAlign='center'>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell textAlign="right" colSpan="2">
                  Tổng:   <NumberFormat value={data.total}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'VND '}
                                                suffix={',000'}/>
                </Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="5">
                  <Link to="/checkout">
                    <Button floated="right" color="yellow">
                      Tiến Hành Thanh Toán
                    </Button>
                  </Link>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchCart: () => dispatch(fetchCart())
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderSummary)
);
