import React from "react";
import {
  Divider,
  Header,
  Embed,
  Item,
  Image,
  Grid,
  Container,
} from "semantic-ui-react";

import { Link } from "react-router-dom";
import Timeline from 'react-image-timeline';
require('react-image-timeline/dist/timeline.css'); // .scss also available

import logo from '../custom_media/logo_PhuocSon.png';
const events = [
    {
        date: new Date(1994, 9, 27),
        text: "“Chúng tôi từng là những thanh niên đầu tiên vào miền Nam làm kinh tế từ những năm 80s. Khoảng năm 1994 chúng tôi bắt đầu đi đến từng nhà dân, mỗi lần chỉ mua được một vài bao cà phê tươi.”",
        title: "Sự khởi đầu khiêm tốn",
        buttonText: '1994',
        imageUrl:  `https://lh3.googleusercontent.com/y8ot4Pyff7fA8qcJ1P8kwJj_1jknhuEAdT-HJTDZ2cABQpbkVLvdqyX0oDNmivv3yx8VnVYWX00IcuPwtwkerZpD9FNIiuLCuSYSb-00Jum8W6fREc0s-tXWJ2lbFiRvroWdsIsg=w2400`,
    },
    {
        date: new Date(1997, 9, 27),
        text: "“Chúng tôi mua chiếc xe cúp đầu tiên ở xã, với chiếc xe cúp 50 ấy, chúng tôi mở rộng thu mua cà phê cho bà con nông dân ở vùng xa hơn.”",
        title: "Chiếc xe cúp đầu tiên",
        buttonText: '1997',
        imageUrl:  `https://lh3.googleusercontent.com/5Tyft3NctFFGPDVj-Mjh_RpCMpDQ3IXjNLuf0wwkTZ735lA6RE-PaBwSH6K7HyjmYQSwd2CSGKkL56gYbKtdY5PvbQsgjaz54kuuOSExHxJQ9lWFAUGFSUzaSoOPURJ5rhXF5yP0=w2400`,
    },
    {
        date: new Date(2002, 6, 12),
        text: "“Chúng tôi có chiếc ô tô tải 8 tấn đầu tiên của cả thị trấn, để phục vụ giao thương và vận tải hàng hóa cho bà con”",
        title: "Chiếc ô tô đầu tiên",
        buttonText: '2002',
        imageUrl: "https://lh3.googleusercontent.com/978n9w250onzdXy2UVLa96ffCEjJnw8aQZS7tBI_23nbzm5QCp1FoYjyS_uyVhOBdaGgFLICSmSoV04Eq0FME7Kn1kE3z92WypV0geYGTC_t9bNHd2oLTif5WrEC4IvBZjJq8SXf=w2400",
    },
    {
        date: new Date(2007, 10, 7),
        text: "“Xây dựng nhà máy và kho chứa hàng với công xuất và quy mô lớn tại Gia Lâm, Lâm Hà, Lâm Đồng.”",
        title: "Xây dựng nhà máy",
        buttonText: '2007',
        imageUrl: "https://lh3.googleusercontent.com/yPAXBWMbH2D28axMwhDWhM1BzSetHMHOYO57a9Rf_-lBH39-VtpU_sWhVXv-aivBNjqmuaIJyPwBaPBGuiUVxlMcltAKkbpCx3gZdoG0xjUOZesr6YBtbfL1mPtfsAO1Yort-mlg=w2400",
    },
    {
        date: new Date(2012, 10, 7),
        text: "“Chính thức đổi tên thành Công ty trách nhiệm hữu hạn Phước Sơn. Trải qua nhiều năm gắn bó với vùng đất Lâm Đồng, chúng tôi quyết định đổi tên công ty để bày tỏ lòng cảm ơn đến với vùng đất núi rừng phù trú nơi đây.”",
        title: "Đổi tên thành Phước Sơn",
        buttonText: '2012',
        imageUrl: "https://lh3.googleusercontent.com/2tp8mLH4DA8XD_dM3ePAA-TVsH6wFbpHETfqkpVzRxWcY1hk4H65w8pr8JbRaHslK1H5aF2A05YlBDiCajHEpsi7S55MWQFqA3xkg9MGQiswCp22AFIEN3DFhfFv4Nhssz9qY3d8=w2400",
    },
    {
        date: new Date(2016, 10, 7),
        text: "“Mở cửa khách sạn Phước Sơn trên Đà Lạt. Nhằm phát triển và đầu tư vào một lĩnh vực mới là dịch vụ lưu trú cao cấp.”",
        title: "Mở cửa khách sạn Phước Sơn",
        buttonText: '2016',
        imageUrl: "https://lh3.googleusercontent.com/Dv8sP5JzK9-7nsICpafBHStxm8_Azfqx0M54HYooxOW2ua98g2MnZR2vJn_0ZLa8bnYkL5FVO8iZTlkmlOtN4CTKdiGosjf7UKAz3159irpyHRASU_c4-PPviHW3I6dJtEZVhSl4=w2400",
    },
    {
        date: new Date(2020, 7, 11),
        text: "“Mảng kinh doanh cà phê xuất khẩu của Phước Sơn hiện giờ đã phát triển với sự kiểm định nghiêm ngặt và quy trình chuẩn bị hàng thành phẩm kỹ lưỡng. Phước Sơn hiện giờ đã có thể tự xuất khẩu cà phê sang các nước Châu Âu, Mỹ, và Nhật Bản.”",
        title: "Xuất khẩu trực tiếp sang nước ngoài.",
        buttonText: '2020',
        imageUrl: "https://lh3.googleusercontent.com/Po3eX2RhHlGi0naB0Xb_L0zzOs9x6JiIGL0oMQyT8kGm4O2fKWdig1PCWwE1zIvEs9xo1wpnZJj7lwp2vXkem3PD0FKWRJMUoSj4mH3ExwnbcJQgRzwA8lnNmEyC1CvTvJL83KJ6=w2400",
    },

];

class AboutLayout extends React.Component {

  render() {
    return (
      <Container>
        {/* Header */}
        <Header as="h2" style={{ fontSize: "2em" }}>
          Giới Thiệu Chung
        </Header>

        <Header as="h3" style={{ color: "brown",fontSize: "2em" }}>
          <b>Phước Sơn™</b>
        </Header>
        {/* Gioi thieu va Video gioi thieu */}
        <Grid columns={2} stackable divided>
         <Grid.Row>
            <Grid.Column  width={9}>
             <Item> 
               <Item.Content>
                 <Item.Description>
                   Phước Sơn là đại diện tập thể anh chị em chúng tôi trên vùng cao nguyên xa xôi đầy nắng gió. Chúng tôi
                   lớn lên từ sự hào phóng của mẹ thiên nhiên và sự cần cù, chất phác của con người. Suốt hơn 20 năm qua,
                   Phước Sơn đã luôn đồng hành cùng người nông dân trồng cà phê trong công cuộc đổi mới đời sống.
                 </Item.Description>
                 <br/>
                 <Item.Extra>
                   Hiện nay, Phước Sơn là một doanh nghiệp lớn ở Lâm Đồng, hoạt động kinh doanh của chúng tôi trên 3 lĩnh vực chính:
                   <p> 
                   1. Chế biến và sản xuất cà phê nhân xanh thành phẩm trong nước và xuất khẩu.<br/>
                   2. Kinh doanh các mặt hàng <Link to="/products">sản phẩm</Link> nổi bật của Lâm Đồng.<br/>
                   3. Kinh doanh dịch vụ lưu trú <Link to="/hotel">khách sạn Phước Sơn</Link>.
                   </p>
                 </Item.Extra>
               </Item.Content>
             </Item>
           </Grid.Column>

           <Grid.Column  width={7}>
           <div>
               <Embed
                 aspectRatio='16:9'
                 active={true}
                 id='W-8hqb2rLWI'
                 placeholder={logo}
                 source='youtube'
               />
           </div>
           </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Divider phan cach */}
        <Divider as="h5" className="header" horizontal style={{ margin: "3em 0em", textTransform: "uppercase" }}>
          <a href="#case-studies">Lịch Sử Công Ty:</a>
        </Divider>

        {/* History time line */}
        <Timeline events={events} />

      </Container>
    )
  };
};
export default AboutLayout;
