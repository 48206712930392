import React from "react";
import { Container, Card,
    Header, Message, Icon,
   Button, Image, Modal, List } from 'semantic-ui-react';

import logo from '../custom_media/logo_PhuocSon.png';
import hall_1 from '../custom_media/hotel/phong_khach_1.jpg';

const careerOptions = [
  {
    id: '1',
    image: hall_1,
    title: 'Lễ tân khách sạn',
    description: 'Công việc toàn thời gian, phù hợp với người có kiến thức về Đà Lạt nói chung.',

    modal_amount_recruit: '1',
    modal_date_posted: '18/12/2020',
    modal_address: '6G Nam Ky Khoi Nghia, Da Lat',
    modal_department: 'Khách Sạn Phước Sơn',
    modal_image: hall_1,
    modal_report_to: 'Quản Lý Khách Sạn',
    modal_description: 'Lễ Tân Khách Sạn được xem là “bộ mặt” của mỗi khách sạn. Không chỉ có nhiệm vụ làm vui lòng khách đến, hài lòng khách đi mà nhân viên lễ tân còn làm những công việc khác liên quan đến vị trí công việc của mình.',

    modal_responsibility: ['Thực hiện quy trình check-in',
                          'Tư vấn, bán dịch vụ cho khách hàng',
                          'Phục vụ khách hàng trong thời gian lưu trú' ,
                          'Thực hiện quy trình check-out',
                          'Các công việc khác'],
    modal_qualifications: ['Tối thiểu 2 năm kinh nghiệm Quản lý Khách Sạn',
                          'Tiếng Anh giao tiếp cơ bản',
                          'Tốt nghiệp Cao đẳng trở lên nghành quản trị khách sạn',
                          'Sơ cứu căn bản' ,
                          'Phòng cháy chữa cháy'],
    modal_contact_person: 'Ms. Thảo Nguyễn (phuocsondalat@gmail.com)',
  },
];

function CareerLayout() {
  const [open, setOpen] = React.useState(false)

  return (
    <Container>
        {/* product list selling point statement */}
        <Header as='h5' textAlign='center'>
          <Header.Content>
              <Message color='brown'>
                <Icon name='coffee'/>Cơ Hội Nghề Nghiệp. <Icon name='coffee'/>
              </Message>
          </Header.Content>
        </Header>

        {/* Load products from api response, and parse them */}
        <Card.Group centered>
          {careerOptions.map(item => {
            return (
              <Card key={item.id}>
                <Image src={item.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>
                    Phước Sơn - {item.title}
                  </Card.Header>
                  <Card.Meta>
                    <span className='address'>Địa Chỉ: {item.modal_address}</span>
                  </Card.Meta>
                  <Card.Description>
                    {item.description}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Modal
                      floated="right"
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      trigger={<Button color='teal'>Xem mô tả công việc</Button>}
                    >
                      {/* Content of Modal pop-ups */}
                      <Modal.Header>{item.title}</Modal.Header>
                      <Modal.Content image scrolling>
                        <Image size='medium' src={item.modal_image} wrapped />

                        <Modal.Description>
                          <p>
                            <Image size='tiny' src={logo}/> <b>Công Ty Phước Sơn</b> - <i>Vị Trí: {item.title}</i>
                          </p>
                          <p>
                            Cơ Sở: {item.modal_department} - địa chỉ: {item.modal_address}.
                          </p>
                          <p>
                            Báo cáo: {item.title} sẽ có nghĩa vụ báo cáo thường xuyên cho {item.modal_report_to}.
                          </p>
                          <p>
                            Mô tả công việc: {item.modal_description}
                          </p>
                          <p>
                            Nghĩa vụ và trách nhiệm:
                            <List bulleted>
                                {item.modal_responsibility.map(r => {
                                  return (<List.Item style={{color: "black", paddingLeft:"2em"}}>{r}</List.Item>)
                                })}
                            </List>
                          </p>
                          <p>
                            Bằng cấp và kinh nghiệm:
                            <List bulleted>
                                {item.modal_qualifications.map(q => {
                                  return (<List.Item style={{color: "black", paddingLeft:"2em"}}>{q}</List.Item>)
                                })}
                            </List>
                          </p>
                          <p>
                            Số lượng tuyển dụng: {item.modal_amount_recruit}
                          </p>
                          <p>
                            Mức lương: thỏa thuận theo năng lực. Công ty sẽ tổ chức tập huấn khi được nhận tuyển.
                          </p>

                          <p>
                            <i>Ngày đăng tuyển: {item.modal_date_posted}</i>
                          </p>
                          <p>
                            Người liên hệ: {item.modal_contact_person}.
                          </p>
                          <p>
                            Hãy liên hệ trực tiếp để nhận thêm thông tin về vị trí công việc này!
                          </p>

                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={() => setOpen(false)} primary>
                          Đóng!
                        </Button>
                      </Modal.Actions>
                    </Modal>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>

    </Container>
  )
}

export default CareerLayout;
