import React from "react";
import { Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import HomepageLayout from "./containers/Home";
import ProductList from "./containers/ProductList";
import ProductDetail from "./containers/ProductDetail";
import OrderSummary from "./containers/OrderSummary";
import Checkout from "./containers/Checkout";
import Profile from "./containers/Profile";
import AboutLayout from "./containers/About";
import SustainabilityLayout from "./containers/Sustainability";
import CareerLayout from "./containers/Career";
import HotelLayout from "./containers/Hotel";
import NewsfeedList from "./containers/NewsfeedList";
import NewsfeedDetail from "./containers/NewsfeedDetail";

const BaseRouter = () => (
  <Hoc>
    <Route exact path="/products" component={ProductList} />
    <Route path="/products/:productID" component={ProductDetail} />
    <Route exact path="/articles" component={NewsfeedList} />
    <Route path="/articles/:articleID" component={NewsfeedDetail} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/order-summary" component={OrderSummary} />
    <Route path="/checkout" component={Checkout} />
    <Route path="/profile" component={Profile} />
    <Route exact path="/" component={HomepageLayout} />
    <Route exact path="/about" component={AboutLayout} />
    <Route exact path="/sustainability" component={SustainabilityLayout} />
    <Route exact path="/career" component={CareerLayout} />
    <Route exact path="/hotel" component={HotelLayout} />
  </Hoc>
);

export default BaseRouter;
