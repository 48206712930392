import React from "react";
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
  Segment,
  Icon,
  Item,
  Label,
  Button,
} from "semantic-ui-react";

import { articleListURL } from "../constants";
import axios from "axios";

class  NewsfeedList extends React.Component {
  state = {
    loading: false,
    error: null,
    data: [],
    width: window.innerWidth,
  };

  componentDidMount() {
    this.setState({ loading: true });
    axios
      .get(articleListURL)
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  }

  render() {
    const { data, error, loading, width } = this.state;
    const isMobile = width < 500;
    const description_mobile_length = 200;
    const description_not_mobile_length = 500;
    return (
      <Container>
          {error && (
            <Message
              error
              header="There was some errors with your submission"
              content={JSON.stringify(error)}
            />
          )}
          {loading && (
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </Segment>
          )}

          {/* product list selling point statement */}
          <Header as='h5' textAlign='center' style={{  marginTop: '0.5em',}}>
            <Header.Content>
                <Message color='brown'>
                  <Icon name='coffee'/> Tin Tức và Thông Báo từ Phước Sơn™ <Icon name='coffee'/>
                </Message>
            </Header.Content>
          </Header>

          {/* Load articles from api response, and parse them */}
          <Item.Group divided>
            {data.map(item => {
              return (
                <Item key={item.id}>
                  <Item.Image src={item.image}/>

                  <Item.Content>
                    <Item.Header as="a"
                                onClick={() =>
                                  this.props.history.push(`/articles/${item.id}`)
                    }>
                          {item.title}
                    </Item.Header>

                    <Item.Meta>
                      <span className='category'>Chủ đề: {item.category}</span>
                    </Item.Meta>
                    <Item.Description>  {(isMobile ?
                                          `${item.description.substring(0, description_mobile_length)}...` :
                                          `${item.description.substring(0, description_not_mobile_length)}...`)}
                    </Item.Description>
                    <Item.Extra>
                      <Label icon='coffee' content={item.tag} />
                      <Button basic
                              color='green'
                              floated='right'
                              onClick={() =>this.props.history.push(`/articles/${item.id}`)}
                      >
                        Đọc Tiếp
                        <Icon name='right chevron' />
                      </Button>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              );
            })}
          </Item.Group>
      </Container>
  	);
  }
};

export default NewsfeedList;
