import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Divider
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authLogin } from "../store/actions/auth";

import { authFaceBookLogin } from "../store/actions/auth";
import { authGoogleLogin } from "../store/actions/auth";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

require('dotenv').config()
const { REACT_APP_FACEBOOK_APP_ID, REACT_APP_GOOGLE_APP_ID } = process.env;
class LoginForm extends React.Component {
  state = {
    username: "",
    password: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFacebookLogin = res => {
    this.props.loginFB('facebook', res.accessToken);
  };

  handleGoogleLogin = res => {
    this.props.loginGoogle('google', res.tokenObj.access_token);
    {/*console.log(res.tokenObj)*/}
  };

  handleSocialLoginFail = e => {
    console.log(e);
  };

  handleHasToken = () => {
    this.props.history.push("/");
    window.location.reload();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  render() {
    const { error, loading, token } = this.props;
    const { username, password } = this.state;
    if (token) {
      this.handleHasToken();
    }

    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Đăng nhập vào tài khoản
          </Header>
          {error && <p>Tài khoản hoặc mật khẩu không chính xác. ({this.props.error.message})</p>}

          <React.Fragment>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  onChange={this.handleChange}
                  value={username}
                  name="username"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Tên đăng nhập. vd: nguyenvan123"
                />
                <Form.Input
                  onChange={this.handleChange}
                  fluid
                  value={password}
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Mật khẩu."
                  type="password"
                />

                <Button
                  color="teal"
                  fluid
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  Login
                </Button>
              </Segment>
            </Form>
            <Message>
              Chưa có tài khoản? <Link to="/signup">Đăng ký tại đây</Link>
            </Message>
          </React.Fragment>

          {/*facebook login
          <React.Fragment>
              <FacebookLogin
               appId={REACT_APP_FACEBOOK_APP_ID}
               autoLoad={false}
               fields="name,email"
               textButton="Đăng nhập với Facebook"
               size="medium"
               isDisabled={true}
               onFailure={(responseFailFacebook) => this.handleSocialLoginFail(responseFailFacebook)}
               callback={(responseFacebook) => this.handleFacebookLogin(responseFacebook)}
               cookie={true}
            />
          </React.Fragment>
          */}

          <Divider/>
          {/*google login*/}
          <GoogleLogin
            clientId={REACT_APP_GOOGLE_APP_ID}
            buttonText="Đăng nhập với Google"
            onFailure={(responseFailGoogle) => this.handleSocialLoginFail(responseFailGoogle)}
            onSuccess={(responseGoogle) => this.handleGoogleLogin(responseGoogle)}
            cookiePolicy={'single_host_origin'}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    loginFB: (provider, access_token) => dispatch(authFaceBookLogin(provider, access_token)),
    loginGoogle: (provider, access_token) => dispatch(authGoogleLogin(provider, access_token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
