import React from "react";
import {
  Divider,
  Header,
  Item,
  Image,
  Grid,
  Container,
  Segment,
  Transition,
  Icon
} from "semantic-ui-react";

import { Link } from "react-router-dom";
import green_sustainability_1 from '../custom_media/sustainability/green_sustainability.jpg';

class SustainabilityLayout extends React.Component {
  state = {
    width: window.innerWidth,
    visible: true,
  };

  render() {
    const { width, visible } = this.props;
    const isMobile = width < 500;

    return (
      <div>
        {/* SCREEN-WIDE IMAGE 1 */}
        <Segment
            inverted
            textAlign='center'
            style={{  maxHeight: 675,
                      padding: '1em 0em 0em 0em',
                      backgroundImage: `url(${green_sustainability_1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      marginLeft:"-9em", marginRight:"-9em", marginTop:"-3em"
                  }}
            vertical
        >
          {/* First big image */}
          <Container>
            <div>
              <Header
                as='h3'
                content='Phát triển bền vững'
                inverted
                style={{
                  fontSize: isMobile ? '0.9em' : '3em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  marginTop: isMobile ? '0.5em' : '6em',
                }}
              />
              <Header
                as='h4'
                content='a green background'
                inverted
                style={{
                  fontSize: isMobile ? '0.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: isMobile ? '0.5em' : '1.5em',
                }}
              />

              <React.Fragment>
                  <div>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                  </div>
                  <Transition animation='bounce' duration={1500} visible={visible}>
                      <Icon className='angle double down'/>
                  </Transition>
              </React.Fragment>
            </div>
          </Container>
        </Segment>


        {/* Divider phan cach */}
        <Divider as="h5" className="header" horizontal style={{ margin: "2em 0em", textTransform: "uppercase" }}>
        </Divider>


        <Grid columns={2} stackable>
          <Grid.Column style={{
                      fontFamily: 'utopia-std-display,serif',
                      fontSize: isMobile ? '0.9em' : '2.2em',
                      fontWeight: 'normal',
                      lineHeight: '1em'
                    }}>
            Sự bền vững chính là trọng tâm trong mô hình kinh doanh của chúng tôi
          </Grid.Column>
          <Grid.Column style={{
                      fontFamily: 'utopia-std-display,serif',
                      fontSize: isMobile ? '0.5em' : '1.0em',
                      fontWeight: 'normal',
                    }}>
            Chúng tôi mong muốn tạo ra giá trị công bằng và bền vững, cho cả doanh nghiệp của mình và
            tất cả các bên liên quan trong chuỗi giá trị: nhân viên, đối tác, cộng đồng chúng tôi tiếp xúc và môi trường xung quanh chúng tôi.
          </Grid.Column>
        </Grid>

        {/* Divider phan cach */}
        <Divider as="h5" className="header" horizontal style={{ margin: "2em 0em", textTransform: "uppercase" }}>
        </Divider>

        {/* SCREEN-WIDE IMAGE 2 */}
        <Segment
            inverted
            textAlign='center'
            style={{  maxHeight: 675,
                      padding: '1em 0em 0em 0em',
                      backgroundImage: `url(${green_sustainability_1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      marginLeft:"-9em", marginRight:"-9em"
                  }}
            vertical
        >
          {/* First big image */}
          <Container>
            <div>
              <Header
                as='h3'
                content='Phước Sơn'
                inverted
                style={{
                  fontSize: isMobile ? '0.9em' : '5em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  marginTop: isMobile ? '0.5em' : '3em',
                }}
              />
              <Header
                as='h4'
                content='Vì Một Việt Nam Tươi Đẹp Hơn'
                inverted
                style={{
                  fontSize: isMobile ? '0.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: isMobile ? '0.5em' : '1.5em',
                }}
              />

              <React.Fragment>
                  <div>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/>
                    <br/>
                  </div>
                  <Transition animation='bounce' duration={1500} visible={visible}>
                      <Icon className='angle double down'/>
                  </Transition>
              </React.Fragment>
            </div>
          </Container>
        </Segment>

        {/* Divider phan cach */}
        <Divider as="h5" className="header" horizontal style={{ margin: "2em 0em", textTransform: "uppercase" }}>
        </Divider>


        <Grid columns={2} stackable  style={{ margin: "1em" }}>
          <Grid.Column style={{
                      fontFamily: 'utopia-std-display,serif',
                      fontSize: isMobile ? '0.9em' : '2.2em',
                      fontWeight: 'normal',
                      lineHeight: '1em'
                    }}>
            Sự bền vững chính là trọng tâm trong mô hình kinh doanh của chúng tôi
          </Grid.Column>
          <Grid.Column style={{
                      fontFamily: 'utopia-std-display,serif',
                      fontSize: isMobile ? '0.5em' : '1.0em',
                      fontWeight: 'normal',
                    }}>
            Chúng tôi mong muốn tạo ra giá trị công bằng và bền vững, cho cả doanh nghiệp của mình và
            tất cả các bên liên quan trong chuỗi giá trị: nhân viên, đối tác, cộng đồng chúng tôi tiếp xúc và môi trường xung quanh chúng tôi.
          </Grid.Column>
        </Grid>

        {/* Divider phan cach */}
        <Divider as="h5" className="header" horizontal style={{ margin: "2em 0em", textTransform: "uppercase" }}>
        </Divider>

        {/* SCREEN-WIDE IMAGE 3 */}
        <Segment
            inverted
            textAlign='center'
            style={{  maxHeight: 675,
                      padding: '1em 0em 0em 0em',
                      backgroundImage: `url(${green_sustainability_1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      marginLeft:"-9em", marginRight:"-9em"
                  }}
            vertical
        >
          {/* First big image */}
          <Container>
            <div>
              <Header
                as='h3'
                content='Phước Sơn'
                inverted
                style={{
                  fontSize: isMobile ? '0.9em' : '5em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  marginTop: isMobile ? '0.5em' : '3em',
                }}
              />
              <Header
                as='h4'
                content='Vì Một Việt Nam Tươi Đẹp Hơn'
                inverted
                style={{
                  fontSize: isMobile ? '0.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: isMobile ? '0.5em' : '1.5em',
                }}
              />

              <React.Fragment>
                  <div>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/>
                    <br/>
                  </div>
                  <Transition animation='bounce' duration={1500} visible={visible}>
                      <Icon className='angle double down'/>
                  </Transition>
              </React.Fragment>
            </div>
          </Container>
        </Segment>
      </div>
    )
  };
};
export default SustainabilityLayout;
