
import React, {createRef} from "react";
import  Carousel  from  'semantic-ui-carousel-react';
import {
  Container,
  Card,
  Button,
  Grid,
  Header,
  Icon,
  Item,
  Message,
  Divider,
  Dropdown,
  Image,
  Segment,
  Sticky,
  Ref,
} from "semantic-ui-react";

import ScrollToTop from "react-scroll-up";

import phone_icon from '../custom_media/hotel/phone.png';
import fb_icon from '../custom_media/hotel/facebook.png';
import mytour_icon from '../custom_media/hotel/mytour.png';
import traveloka_icon from '../custom_media/hotel/Traveloka.png';
import agoda_icon from '../custom_media/hotel/agoda.png';
import booking_icon from '../custom_media/hotel/booking.png';
import tripadvisor_icon from '../custom_media/hotel/tripadvisor.png';

import amenity_coffee from '../custom_media/hotel/caffe6.png';
import amenity_gym from '../custom_media/hotel/gym1.png';
import amenity_road from '../custom_media/hotel/road.png';
import amenity_hall from '../custom_media/hotel/phong_khach_2.jpg';

import single_room_1 from '../custom_media/hotel/phong_don_1.jpg';
import single_room_2 from '../custom_media/hotel/phong_don_2.jpg';
import single_room_3 from '../custom_media/hotel/phong_don_3.jpg';

import double_room_1 from '../custom_media/hotel/phong_doi_1.jpg';
import double_room_2 from '../custom_media/hotel/phong_doi_2.jpg';
import double_room_3 from '../custom_media/hotel/phong_don_3.jpg';
import double_room_4 from '../custom_media/hotel/phong_doi_4.jpg';

import tribble_room_1 from '../custom_media/hotel/phong_ba_1.jpg';
import tribble_room_2 from '../custom_media/hotel/phong_ba_2.jpg';

import reception_1 from '../custom_media/hotel/le_tan_1.jpg';
import reception_2 from '../custom_media/hotel/le_tan_2.jpg';
import reception_3 from '../custom_media/hotel/le_tan_3.jpg';

import hall_1 from '../custom_media/hotel/phong_khach_1.jpg';
import hall_2 from '../custom_media/hotel/phong_khach_2.jpg';
import hall_3 from '../custom_media/hotel/phong_khach_3.jpg';
import hall_4 from '../custom_media/hotel/phong_khach_4.jpg';


const platformOptions = [
  {
    key: '#',
    text: '0964.500.289',
    value: 'Phone',
    image: { avatar: true, src: phone_icon},
  },
  {
    key: 'https://www.facebook.com/hotelphuocson',
    text: 'Facebook',
    value: 'Facebook',
    image: { avatar: true, src: fb_icon },
  },
  {
    key: 'https://www.booking.com/hotel/vn/phuoc-son.vi.html',
    text: '',
    value: 'Booking',
    image: { avatar: true, src: booking_icon },
  },
  {
    key: 'https://www.traveloka.com/vi-vn/hotel/vietnam/hotel-phuoc-son-dalat-3000010020449',
    text: '',
    value: 'Traveloka',
    image: { avatar: true, src: traveloka_icon },
  },
  {
    key: 'https://mytour.vn/13423-khach-san-phuoc-son.html',
    text: 'Mytour',
    value: 'Mytour',
    image: { avatar: true, src: mytour_icon },
  },
  {
    key: 'https://www.agoda.com/hotel-phuoc-son/hotel/dalat-vn.html?cid=1844104',
    text: 'Agoda',
    value: 'Agoda',
    image: { avatar: true, src: agoda_icon },
  },
  {
    key: 'https://www.tripadvisor.co.nz/Hotel_Review-g293922-d11805056-Reviews-Phuoc_Son_Hotel-Da_Lat_Lam_Dong_Province.html',
    text: '',
    value: 'Tripadvisor',
    image: { avatar: true, src: tripadvisor_icon },
  },
];

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
};



const  HotelLayout  = () => {
	let  single_room_elements  = [
		{
			render:()=>{
				return <Image size='big' src={single_room_1} />
			}
		},
		{
			render:()=>{
				return <Image size='big' src={single_room_2} />
			}
    },
		{
			render:()=>{
				return <Image size='big' src={single_room_3} />
			}
    },
	];
	let  double_room_elements  = [
		{
			render:()=>{
				return <Image size='big' src={double_room_1} />
			}
		},
		{
			render:()=>{
				return <Image size='big' src={double_room_2} />
			}
    },
		{
			render:()=>{
				return <Image size='big' src={double_room_3} />
			}
    },
		{
			render:()=>{
				return <Image size='big' src={double_room_4} />
			}
    },
	];
	let  tribble_room_elements  = [
		{
			render:()=>{
				return <Image size='big' src={tribble_room_1} />
			}
		},
		{
			render:()=>{
				return <Image size='big' src={tribble_room_2} />
			}
    },

	];
  let  reception_elements  = [
		{
			render:()=>{
				return <Image size='huge' src={reception_1} />
			}
		},
		{
			render:()=>{
				return <Image size='huge' src={reception_2} />
			}
    },
		{
			render:()=>{
				return <Image size='huge' src={reception_3} />
			}
    },
	];
  let  hall_elements  = [
		{
			render:()=>{
				return <Image size='huge' src={hall_1} />
			}
		},
		{
			render:()=>{
				return <Image size='huge' src={hall_2} />
			}
    },
		{
			render:()=>{
				return <Image size='huge' src={hall_3} />
			}
    },
		{
			render:()=>{
				return <Image size='large' src={hall_4} />
			}
    },
	];

  let contextRef = createRef()
	return (
    <Container>
      {/* Hotel selling point statement */}
      <Header as='h5' textAlign='center'>
        <Header.Content>
            <Message color='brown'>
              <Icon name='hotel'/>Chào mừng quý khách đến với khách sạn Phước Sơn!   <Icon name='hotel'/>
            </Message>
        </Header.Content>
      </Header>

      <Ref innerRef={contextRef}>
        <Container>
          {/* Sticky Divider */}
          <Sticky context={contextRef}>
            <Divider horizontal style={{marginTop: "4em"}}>
                <Button.Group color='green'>
                  <Button>Đặt Phòng Ngay</Button>
                  <Dropdown
                    icon='hand point down'
                    floating
                    className='button icon'
                  >
                    <Dropdown.Menu>
                      <Dropdown.Header content='Các nền tảng đặt phòng' />
                      {platformOptions.map((option) => (
                        <Dropdown.Item key={option.value} {...option}
                                       onClick={() => {openInNewTab(option.key)}}
                      />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Button.Group>

            </Divider>
          </Sticky>


        <Grid stackable columns={2} divided>
          <Grid.Row>
            {/* Left Screen */}
            <Grid.Column width={7}>
              <Header as='h4' icon textAlign='center'>
                <Icon name='home' circular />
                <Header.Content>
                    Tiện nghi tại khách sạn Phước Sơn
                    <Header.Subheader>Đem sự thoải mái và ấm áp vào chuyến đi của bạn</Header.Subheader>
                </Header.Content>
              </Header>

              <Item.Group divided>
                <Item>
                  <Item.Image size='small' src={amenity_coffee} />

                  <Item.Content verticalAlign='middle'>
                    <Icon name='coffee' />
                     Cafe nguyên chất chuẩn đà lạt, cafe sạch lại ngon, phục vụ miễn phí mỗi ngày
                  </Item.Content>
                </Item>

                <Item>
                  <Item.Image size='small' src={amenity_gym} />

                  <Item.Content verticalAlign='middle'>
                  <Icon name='heart' />
                   Phòng tập Gym cho những quý khách ở dài ngày tập luyện giữ gìn sức khỏe
                   (bật mí view từ Phòng gym nhìn ra thành phố cũng đẹp lắm nhé)
                  </Item.Content>
                </Item>

                <Item>
                  <Item.Image size='small' src={amenity_road} />

                  <Item.Content verticalAlign='middle'>
                    <Icon name='road' />
                     Cách chợ đà lạt chỉ 5' đi bộ, thuận tiện đi lại trong khu trung tâm, nhà hàng
                  </Item.Content>
                </Item>

                <Item>
                  <Item.Image size='small' src={amenity_hall} />

                  <Item.Content verticalAlign='middle'>
                    <Icon name='car'/>
                     Hầm giữ xe 4,7 chỗ và xe máy rất an toàn.
                     <br/>
                     <Icon name='handshake'/>
                     Sảnh tiếp khách khang trang và sạch sẽ
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>

            {/* Right Screen */}
            <Grid.Column width={9}>
                <Header as='h4' icon textAlign='center'>
                  <Icon name='hotel' circular />
                  <Header.Content>
                      Các loại phòng tại khách sạn Phước Sơn
                      <Header.Subheader>Mang lại những trải nghiệm đặc biệt</Header.Subheader>
                  </Header.Content>
                </Header>

                <Card.Group centered>
                      <Card>
                          <Carousel
                            elements  =  {  single_room_elements  }
                            duration  ={5000}
                            animation  ='slide left'
                            showIndicators  ={true}
                          />
                        <Card.Content>
                          <Card.Header>
                            Phòng Đơn
                          </Card.Header>
                          <Card.Meta>
                            Phòng Một Giường (1 Double Bed)
                          </Card.Meta>
                          <Card.Description>
                            Phù hợp với cặp đôi hoặc quý khách đi công tác một mình. Các phòng đơn đều có ban công nhìn ra phố rất thoáng và nhiều ánh sáng.
                          </Card.Description>
                        </Card.Content>
                      </Card>

                      <Card>
                          <Carousel
                            elements  =  {  double_room_elements  }
                            duration  ={5000}
                            animation  ='slide left'
                            showIndicators  ={true}
                          />
                        <Card.Content>
                          <Card.Header>
                            Phòng Đôi
                          </Card.Header>
                          <Card.Meta>
                            Phòng hai giường (2 Twin Double Beds)
                          </Card.Meta>
                          <Card.Description>
                            Phù hợp với quý khách đi du lịch cùng gia đình. Một căn phòng ấm cúng để bạn trở về sau mỗi ngày khám phá thành phố và thiên nhiên.
                          </Card.Description>
                        </Card.Content>
                      </Card>

                      <Card>
                          <Carousel
                            elements  =  {  tribble_room_elements  }
                            duration  ={5000}
                            animation  ='slide left'
                            showIndicators  ={true}
                          />
                        <Card.Content>
                          <Card.Header>
                            Phòng Đại Gia Đình
                          </Card.Header>
                          <Card.Meta>
                            Phòng ba giường (3 Family Double Beds)
                          </Card.Meta>
                          <Card.Description>
                            Phù hợp với đại gia đình gồm cả người lớn và trẻ nhỏ. Diện tích rộng rãi giúp sinh hoạt dễ dàng và thoải mái.
                          </Card.Description>
                        </Card.Content>
                      </Card>
                </Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br/>
        <br/>
        <Divider horizontal> Quầy lễ tân và Sảnh tiếp khách. </Divider>

        <Grid stackable columns={2} divided>
          <Grid.Column width={8}>
              <Carousel
                elements  =  {  reception_elements  }
                duration  = {6000}
                animation  = 'slide left'
                showIndicators  = {true}
              />
              <Header as='h4' textAlign='center'>
                <Header.Content>
                    Quầy Lễ Tân
                    <Header.Subheader>Luôn lắng nghe và thấu hiểu quý khách.</Header.Subheader>
                </Header.Content>
              </Header>
          </Grid.Column>
          <Grid.Column width={8}>
              <Carousel
                elements  =  {  hall_elements  }
                duration  ={6000}
                animation  ='slide left'
                showIndicators  ={true}
              />
              <Header as='h4' textAlign='center'>
                <Header.Content>
                    Sảnh Tiếp Khách
                    <Header.Subheader>Phòng khách lầu một và lầu hai có thể được sử dụng để đón tiếp khách.</Header.Subheader>
                </Header.Content>
              </Header>
          </Grid.Column>
        </Grid>

        <ScrollToTop showUnder={160}>
          <Icon name="arrow circle up" size="big" color="teal"/>
        </ScrollToTop>
        </Container>
      </Ref>
    </Container>
	);
}
export default HotelLayout;
