import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import NumberFormat from 'react-number-format';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Item,
  Label,
  Loader,
  Message,
  Segment,
  Divider
} from "semantic-ui-react";
import { productDetailURL, addToCartURL } from "../constants";
import { fetchCart } from "../store/actions/cart";
import { authAxios } from "../utils";
import ScrollToTop from "react-scroll-up";


require('dotenv').config()
const { REACT_APP_BACKEND_URL } = process.env;

class ProductDetail extends React.Component {
  state = {
    loading: false,
    error: null,
    data: [],
    width: window.innerWidth,
  };

  componentDidMount() {
    this.handleFetchItem();
  }

  handleFetchItem = () => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    axios
      .get(productDetailURL(params.productID))
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleFormatData = formData => {
    // convert {colour: 1, size: 2} to [1,2] - they're all variations
    return Object.keys(formData).map(key => {
      return formData[key];
    });
  };

  handleAddToCart = slug => {
    this.setState({ loading: true });
    authAxios
      .post(addToCartURL, { slug })
      .then(res => {
        this.props.refreshCart();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleLogin = () => {
      this.props.history.push('/login');
  }


  render() {
    const { data, error, loading, width } = this.state;
    const { isAuthenticated } = this.props;
    const isMobile = width < 500;
    const item = data;
    return (
      <Container>
        {error && (
          <Message
            error
            header="There was some errors with your submission"
            content={JSON.stringify(error)}
          />
        )}
        {loading && (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        <Grid columns={2} divided inverted stackable>
          <Grid.Row>
            <Grid.Column>
              <Card
                fluid
                image={item.image}
                header={
                  <React.Fragment>
                    <Header size='huge' style={{display: 'inline'}}>{item.title}</Header>
                    { item.discount_price == null ? (
                      <Label circular color='yellow' size='large'  key='yellow'>

                        <NumberFormat value={item.price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'VND '}
                                      suffix={',000'}/>
                      </Label>
                    ):(
                      <React.Fragment>
                          <Label circular color='red' size='large'  key='yellow'>
                            <strike>
                            <NumberFormat value={item.price}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'VND '}
                                          suffix={',000'}/>
                            </strike>
                          </Label>
                          <Label circular color='yellow' size='medium'  key='yellow'>

                            <NumberFormat value={item.discount_price}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'VND '}
                                          suffix={',000'}/>
                          </Label>
                      </React.Fragment>
                    )}
                    <br/>
                  </React.Fragment>
                }

                meta={
                  <React.Fragment>{item.category}
                      <Label
                        tag
                        style={{float: 'inline-end'}}
                        color={
                          item.label === "Mới"
                            ? "blue"
                            : item.label === "Ưa chuộng"
                            ? "green"
                            : "red"
                          }
                      >  {item.label}
                      </Label>
                  </React.Fragment>
                }


                description={item.description}
                extra={
                  <React.Fragment>
                    {isAuthenticated ? (
                      <Button
                        fluid
                        color="yellow"
                        floated="right"
                        icon
                        labelPosition="right"
                        onClick={() => this.handleAddToCart(item.slug)}
                      >
                        Thêm vào giỏ
                        <Icon name="cart plus" />
                      </Button>
                    ) : (
                      <Button
                        fluid
                        color="yellow"
                        floated="right"
                        icon
                        labelPosition="right"
                        onClick={() => this.handleLogin()}
                      >
                        Thêm vào giỏ
                        <Icon name="cart plus" />
                      </Button>
                    )}
                  </React.Fragment>
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Header as="h2">Về sản phẩm</Header>
              {data.variations &&
                data.variations.map(v => {
                  return (
                    <React.Fragment key={v.id}>
                      <Header as="h3">{v.name}</Header>
                      <Item.Group divided>
                        {v.item_variations.sort((a, b) => a.id > b.id ? 1:-1).map(iv => {
                          return (
                            <Item key={iv.id}>
                              {iv.attachment && (
                                <Item.Image
                                  size="small"
                                  src={`${REACT_APP_BACKEND_URL}${iv.attachment}`}
                                />
                              )}
                              <Item.Content verticalAlign="middle">
                                {iv.value}
                              </Item.Content>
                            </Item>
                          );
                        })}
                      </Item.Group>

                      {(isMobile ?
                          (<React.Fragment>
                            {isAuthenticated ? (
                              <Button
                                fluid
                                color="yellow"
                                floated="right"
                                icon
                                labelPosition="right"
                                onClick={() => this.handleAddToCart(item.slug)}
                              >
                                Thêm vào giỏ
                                <Icon name="cart plus" />
                              </Button>
                            ) : (
                              <Button
                                fluid
                                color="yellow"
                                floated="right"
                                icon
                                labelPosition="right"
                                onClick={() => this.handleLogin()}
                              >
                                Thêm vào giỏ
                                <Icon name="cart plus" />
                              </Button>
                            )}
                          </React.Fragment>) : '')}
                    </React.Fragment>
                  );
                })}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* TODO: Planning to put a product recommendation here! */}
        <Divider as="h5" className="header" horizontal style={{ margin: "3em 0em", textTransform: "uppercase" }}>
          <a href="#popular-products">Sản phẩm được ưa chuộng nhất </a>
          <br/>...
        </Divider>


        {/* ScrollToTop */}
        <ScrollToTop showUnder={160}>
          <Icon name="arrow circle up" size="big" color="teal"/>
        </ScrollToTop>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshCart: () => dispatch(fetchCart())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDetail)
);
