import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import NumberFormat from 'react-number-format';
import {
  Container,
  Dimmer,
  Header,
  Image,
  Label,
  Loader,
  Message,
  Segment,
  Card,
  Icon,
  Button,
  Dropdown,
  Divider
} from "semantic-ui-react";
import { productListURL, addToCartURL } from "../constants";
import { fetchCart } from "../store/actions/cart";
import { authAxios } from "../utils";
import ScrollToTop from "react-scroll-up";

import ca_phe_truyen_thong from '../custom_media/Products/CaPheTruyenThong_Bich500g.jpg';
import ca_phe_arabica from '../custom_media/Products/Arabica_100_Bich500g.jpg';
import ca_phe_honey from '../custom_media/Products/CaPheHoney_Bich500g.jpg';
import macca_hop_500g from '../custom_media/Products/Macca_Hop500g.jpg';
import macca_hop_250g from '../custom_media/Products/Macca_hop_250g.jpeg';
import macca_bich_500g from '../custom_media/Products/Macca_bich_500g.jpeg';
import macca_nhan_trang_100_hop_250g from '../custom_media/Products/macca_nhan_trang_Hop250g.jpeg';

const products_list = [
  {
    id: 1,
    title: 'Cà Phê Phước Sơn Truyền Thống (Bịch 500g)',
    price: 140,
    discount_price: 130,
    category: 'Cà Phê',
    label: 'Ưa chuộng',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Sự kết hợp tuyệt vời từ 80% Robusta và 20% Arabica. Với quy trình rang say đặc biệt tạo nên hương vị truyền thống của cà phê Lâm Đồng',
    image: ca_phe_truyen_thong
  },
  {
    id: 2,
    title: 'Cà Phê Arabica Cầu Đất Nguyên Chất (Bịch 500g)',
    price: 140,
    discount_price: null,
    category: 'Cà Phê',
    label: 'Ưa chuộng',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Những hạt cà phê Arabica từ Cầu Đất được tuyển chọn kỹ lưỡng với mùi hương Arabica tự nhiên được giữ lại qua quá trình rang say đặc biệt',
    image: ca_phe_arabica
  },
  {
    id: 3,
    title: 'Cà Phê Honey (Bịch 500g)',
    price: 145,
    discount_price: 140,
    category: 'Cà Phê',
    label: 'Mới',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Cà phê Honey được chế biến ướt từ quả cà phê tươi được hái chín chọn lọc và phơi dưới ánh nắng mặt trời. Cà phê Honey có hương bị đậm ngọt hậu vị',
    image: ca_phe_honey
  },
  {
    id: 4,
    title: 'Macca Sấy Nứt (Hộp 500g)',
    price: 150,
    discount_price: 140,
    category: 'Macca',
    label: 'Mới',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Macca Lâm Đồng được sấy nứt và đóng hộp sang trọng. Rất phù hợp làm quà tặng cho đối tác, người thân và bạn bè',
    image: macca_hop_500g
  },
  {
    id: 5,
    title: 'Macca Sấy Nứt (Hộp 250g)',
    price: 80,
    discount_price: 70,
    category: 'Macca',
    label: 'Đặc biệt',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Macca Lâm Đồng được sấy nứt và đóng hộp sang trọng. Bản Macca hộp 250g là một bản đặc biệt rất phù hợp làm quà tặng và sử dụng',
    image: macca_hop_250g
  },
  {
    id: 6,
    title: 'Macca Sấy Nứt (Bịch 500g)',
    price: 150,
    discount_price: 140,
    category: 'Macca',
    label: 'Ưa chuộng',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Macca được mệnh danh là nữ hoàng của các loại hạt với nguồn dinh dưỡng dồi dào và vị béo bùi đặc trưng.',
    image: macca_bich_500g
  },
  {
    id: 7,
    title: 'Macca Sấy Nứt 100% Nhân Trắng (Hộp 250g)',
    price: 260,
    discount_price: 250,
    category: 'Macca',
    label: 'Đặc biệt',
    slug: 'https://nongsanphuocson.kiotvietweb.vn/',
    description: 'Macca Sấy Nứt 100% Nhân Trắng hộp 250g là bản đặc biệt của sản phẩm macca sấy nứt.',
    image: macca_nhan_trang_100_hop_250g
  },
]

class ProductList extends React.Component {
  state = {
    loading: false,
    error: null,
    data: [],
    width: window.innerWidth,
    sortProperty: 'category',
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.setState({ data: products_list, loading: false });
  }

  handleAddToCart = slug => {
    this.setState({ loading: true });
    authAxios
      .post(addToCartURL, { slug })
      .then(res => {
        this.props.refreshCart();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleLogin = () => {
      this.props.history.push('/login');
  };

  handleChangeSortOption = (key) => {
    this.setState({ sortProperty: key });
  };

  handleDynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        console.log('Sorting is working');
        return result * sortOrder;
    }
  };

  render() {
    const { isAuthenticated } = this.props;
    const { data, error, loading, width, sortProperty } = this.state;
    const isMobile = width < 500;
    const description_mobile_length = 135;
    const description_not_mobile_length = 250;

    const sortOptions = [
      {
        key: 'category',
        text: '',
        value: 'category',
        label: (<span style={{color: '#808080'}} >Loại sản phẩm</span>),
      },
      {
        key: 'label',
        text: ' ',
        value: 'label',
        label: (<Label as='a' tag color='teal'> Trạng thái sản phẩm</Label>),
      },
      {
        key: 'price',
        text: '',
        value: 'price',
        label: (<Label as='a' circular color='yellow'>VND Giá sản phẩm</Label>),
      },
    ]
    return (
      <Container>
        {error && (
          <Message
            error
            header="There was some errors with your submission"
            content={JSON.stringify(error)}
          />
        )}
        {loading && (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {/* product list selling point statement */}
        <Header as='h5' textAlign='center'>
          <Header.Content>
              <Message color='brown'>
                <Icon name='coffee'/>Món ngon & đặc sản từ Lâm Đồng thân mến gửi quý anh chị em ở khắp mọi miền Tổ Quốc. <Icon name='coffee'/>
              </Message>

              {/* Sorting */}
              <Button.Group color='brown' floated='right'>
                <Dropdown
                  icon='sort alphabet down'
                  floating
                  className='button icon'
                  color='brown'
                  pointing='right'
                  text='Sắp xếp '
                  style={{padding: '0.4em'}}
                >
                  <Dropdown.Menu>
                      <Dropdown.Header content='Sắp xếp sản phẩm bởi:' />

                      {sortOptions.map((option) => (
                        <Dropdown.Item key={option.value} {...option}
                                       onClick={() => {this.handleChangeSortOption(option.key)}}
                      />
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
          </Header.Content>
        </Header>

        {/* Load products from api response, and parse them */}
        <Card.Group centered>
          {data.sort( this.handleDynamicSort(sortProperty)).map(item => {
            return (
              <Card key={item.id}>
                <Image src={item.image} wrapped ui={false} />
                <Card.Content >
                  <Card.Header a >
                    {item.title}
                    {<Label
                        tag
                        color={
                          item.label === "Mới"
                            ? "blue"
                            : item.label === "Ưa chuộng"
                            ? "green"
                            : "red"
                        }
                        style={{float: 'inline-end'}}>
                      {item.label}
                      </Label>
                    }
                  </Card.Header>
                  <Card.Meta>
                    <span className='category'>{item.category}</span>
                  </Card.Meta>
                  <Card.Description>
                              {(isMobile ?
                                `${item.description.substring(0, description_mobile_length)}` :
                                `${item.description.substring(0, description_not_mobile_length)}`)}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                { item.discount_price == null ? (
                  <Label circular color='yellow' size='large' >

                    <NumberFormat value={item.price}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'VND '}
                                  suffix={',000'}/>
                  </Label>
                ):(
                  <React.Fragment>
                      <Label circular color='red' size='large'>
                        <strike>
                        <NumberFormat value={item.price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'VND '}
                                      suffix={',000'}/>
                        </strike>
                      </Label>
                      <Label circular color='yellow' size='medium'>

                        <NumberFormat value={item.discount_price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'VND '}
                                      suffix={',000'}/>
                      </Label>
                  </React.Fragment>
                )}

                <a href="https://nongsanphuocson.kiotvietweb.vn/" target="_blank" rel="noopener noreferrer">
                  <Button floated="right" icon>
                    <Icon name="cart plus" />
                  </Button>
                </a>
                {/*
                {isAuthenticated ? (
                    <Button
                      floated="right"
                      icon
                      onClick={() => this.handleAddToCart(item.slug)}
                    ><Icon name="cart plus" />
                    </Button>
                ) : (
                    <Button
                      floated="right"
                      icon
                    ><Icon name="cart plus" />
                    </Button>
                )}
                */}
                </Card.Content>

              </Card>
            );
          })}
        </Card.Group>

        {/* ScrollToTop */}
        <ScrollToTop showUnder={160}>
          <Icon name="arrow circle up" size="big" color="teal"/>
        </ScrollToTop>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshCart: () => dispatch(fetchCart())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
