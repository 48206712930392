import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
  Segment,
  Divider,
  Image
} from "semantic-ui-react";

import { articleDetailURL } from "../constants";
import axios from "axios";


class NewsfeedDetail extends React.Component {
  state = {
    loading: false,
    error: null,
    data: [],
    width: window.innerWidth,
  };

  componentDidMount() {
    this.handleFetchItem();
  };

  handleFetchItem = () => {
    const {
      match: { params }
    } = this.props;

    this.setState({ loading: true });

    axios
      .get(articleDetailURL(params.articleID))
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  render() {
    const { data, error, loading, width } = this.state;
    const item = data;
    const isMobile = width < 500;
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

    return (
      <Container>
          {error && (
            <Message
              error
              header="There was some errors with your submission"
              content={JSON.stringify(error)}
            />
          )}
          {loading && (
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </Segment>
          )}

          {/* Title*/}
          <Header as='h1' textAlign='center' style={{  marginTop: '0.5em', }} >
            <Header.Content>
                   {item.title}
            </Header.Content>
          </Header>

          <div class="post-content" style={{paddingLeft: isMobile ? '0px' : '15%',
                                            paddingRight: isMobile ? '0px' : '15%',}}>
            {/* Created date, Updated date*/}
            <Container textAlign='right'>
                <small>
                    <div>
                      Đăng Bài: {new Date(item.created_date).toLocaleDateString('vi-VN', DATE_OPTIONS)}
                    </div>
                    <div >
                      Cập Nhật: {new Date(item.updated_date).toLocaleDateString('vi-VN', DATE_OPTIONS)}
                    </div>

                    <div style={{color: '#808080'}}>
                     Tag:&nbsp;{item.tag} |&nbsp; Category:&nbsp;{item.category}
                    </div>

                </small>
            </Container>

            <Divider/>

            {/* Body Post */}
            <Container textAlign='justified'>
                <Image src={item.image} size='large' centered rounded />
                <br/>

                <ReactMarkdown source={item.body} />
            </Container>

            <br/>
            <Divider/>

            {/* Author Credit*/}
            <Container textAlign='right' style={{  marginTop: '1em',}}>
                <small>
                    <span style={{color: '#808080', fontSize: '1.3em', fontStyle: 'italic', fontFamily: 'courier',}}>
                      Published by  • {item.author}
                    </span>
                </small>
            </Container>
          </div>
      </Container>
    );
  };
};


export default NewsfeedDetail;
